<script setup lang="ts">
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { ShiftAvailability } from '@app/types/shifts'
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { computed } from 'vue'
import CreateShift from './actions/CreateShift.vue'
import DeleteAvailability from './actions/DeleteAvailability.vue'

const { availability } = defineProps<{
    availability: ShiftAvailability
}>()

const from = computed(() => getTimeFromDateTime(availability.from))
const to = computed(() => getTimeFromDateTime(availability.to))
const date = computed(() => getDateFromDateTime(availability.from))

const emit = defineEmits(['close'])
</script>

<template>
    <DetailCard :model="availability" :dismissable="true" @close="emit('close')" class="min-w-72 md:!w-1/3">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle>{{ $t('attributes.availability') }}</DetailCardTitle>
            <div class="flex flex-col items-end gap-4 text-base">
                <DetailCardLabel :label="$t('attributes.id')">
                    <span>{{ availability.id }}</span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.client_project')">
                    <span>{{ availability.client_project_name }}</span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.date')">
                    <span>{{ date }}</span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.time')">
                    <span>{{ from }} - {{ to }}</span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.branch')">
                    <span>{{ availability.branch_name }}</span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.location_type')">
                    <span>{{ availability.location_type_name }}</span>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <DeleteAvailability :availability @delete="$emit('close')" />
            <CreateShift :availability />
        </template>
    </DetailCard>
</template>
