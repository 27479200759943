<script setup lang="ts">
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import type { ContractModel } from '@app/types/contracts'
import axios from 'axios'
import { formatISO } from 'date-fns'
import { ref } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{ id?: number; model?: ContractModel }>()

const previewShown = ref(false)

const content = ref()

async function showPreview() {
    const { data } = await loadPreview()

    content.value = data
    previewShown.value = true
}

async function loadPreview() {
    if (props.id) {
        return axios.post(route('contract.preview', props.id))
    }

    if (props.model) {
        return axios.post(route('contract.preview'), {
            template: 'contract',
            ...props.model,
            start_date: props.model.start_date ? formatISO(props.model.start_date, { representation: 'date' }) : null,
            end_date: props.model.end_date ? formatISO(props.model.end_date, { representation: 'date' }) : null,
        })
    }
}
</script>

<template>
    <div>
        <slot :showPreview></slot>
        <Teleport to="body">
            <ModalLayout :show="previewShown" @close="previewShown = false" size="4xl">
                <div class="p-8" v-html="content"></div>
            </ModalLayout>
        </Teleport>
    </div>
</template>
