<script setup lang="ts">
import { computed } from 'vue'
import type { FormStep } from './types'

const { steps } = defineProps<{
    steps: FormStep[]
}>()

const currentStepNumber = computed(() => steps.findIndex(({ isActive }) => isActive))
</script>

<template>
    <ul class="hidden gap-8 lg:flex">
        <slot></slot>
    </ul>

    <div class="flex items-center justify-center gap-8 lg:hidden">
        <div class="text-sm font-medium leading-4 text-gray-900">
            {{
                $t('form.multistep.overview', {
                    step: (currentStepNumber + 1).toString(),
                    total: steps.length.toString(),
                })
            }}
        </div>

        <div class="flex gap-5">
            <span
                v-for="step in steps"
                class="inline-block h-[10px] w-[10px] rounded-full"
                :class="[step.isActive || step.isPassed ? 'bg-primary-600' : 'bg-gray-200', step.isActive ? 'outline outline-4 outline-primary-100' : '']"
            ></span>
        </div>
    </div>
</template>
