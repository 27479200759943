<template>
    <div>
        <div class="relative px-1">
            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                <slot name="icon" />
            </div>
        </div>
    </div>
    <div class="min-w-0 flex-1">
        <div class="text-sm text-zinc-900">
            <slot name="header" />
        </div>
        <div class="mt-2 text-sm text-gray-500">
            <p>
                <slot name="content" />
            </p>
        </div>
    </div>
</template>
