<script setup lang="ts">
import type { Graph, GraphData } from '@app/types/metrics'
import axios from 'axios'
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue'
import { route } from 'ziggy-js'
import LineGraph from './graphs/LineGraph.vue'
import GraphSummary from './GraphSummary.vue'

const { graph, filters, location } = defineProps<{ graph: Graph; location: string; filters: Record<string, unknown> }>()

const graphFilters = computed(() => {
    const query: typeof filters = {}

    graph.filters.forEach((property) => {
        query[property] = filters[property] ?? null
    })

    return query
})

const graphKey = computed(() => btoa(JSON.stringify(graphFilters.value)))

const emit = defineEmits(['click:legend'])

const rootElement = useTemplateRef('root')

const loaded = ref(false)
const graphData = ref<GraphData>()

async function loadMetric() {
    loaded.value = false
    const { data } = await axios.get(
        route('api.v1.graph.show', {
            graphType: graph.type,
            ...graphFilters.value,
            location,
        })
    )

    graphData.value = data

    loaded.value = true
}

watch(graphFilters, () => {
    if (loaded.value) {
        loadMetric()
    }
})

onMounted(() => {
    const observer = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) {
            return
        }

        loadMetric()

        observer.unobserve(rootElement.value)
    })

    if (rootElement.value) {
        observer.observe(rootElement.value)
    }

    return () => {
        observer.disconnect()
    }
})
</script>

<template>
    <div ref="root" class="bg-white-800 min-h-48 w-full rounded-lg border border-zinc-100 p-4">
        <div class="flex flex-col justify-between gap-2">
            <div class="flex flex-wrap items-start justify-between">
                <h3 class="w-1/2 text-base font-medium leading-6 text-zinc-600">{{ $t(`dashboard.kpi.${graph.type}`) }}</h3>
                <div v-if="graphData" class="text-sm font-medium uppercase leading-5 text-zinc-400">{{ $t(graphData.range) }}</div>
            </div>

            <GraphSummary v-if="graphData?.summary" :summary="graphData.summary" :type="graphData.summary.type" />
        </div>
        <div class="relative">
            <LineGraph
                v-if="graphData"
                :graph="graphData"
                :type="graph.type"
                @click:legend="(name, item) => $emit('click:legend', name, item)"
                :key="graphKey"
            />
        </div>
    </div>
</template>
