<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Contract } from '@app/types/contracts'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import ContractChangeForm from '../forms/ContractChangeForm.vue'

const { contract } = defineProps<{ contract: Contract }>()

const { can } = useUserCan()
const { toggle } = useActiveButton()

const show = computed(() => can('contract.store') && contract.status.slug === 'active' && !contract.has_next_contract)
</script>

<template>
    <RoundButton v-if="show" :label="$t('contract.actions.edit')" @click="toggle(ContractChangeForm)">
        <PencilSquareIcon class="size-4" aria-hidden="true" />
    </RoundButton>
</template>
