import { computed, ref } from 'vue'

type TimerOptions = {
    mode: 'timer' | 'stopwatch'
    initial: number
}

export function useTimer(options: Partial<TimerOptions> = {}) {
    let timerId: number | undefined

    const { initial, mode } = {
        mode: 'stopwatch',
        initial: 0,
        ...options,
    }

    const seconds = ref(initial)

    const stopwatch = computed(() => {
        const date = new Date(1000 * seconds.value)

        return date.toISOString().substring(14, 19)
    })

    function startTimer() {
        timerId = setInterval(() => {
            if (mode === 'stopwatch') {
                seconds.value++
            } else {
                seconds.value--
            }
        }, 1000)
    }

    function stopTimer() {
        clearInterval(timerId)
    }

    function resetTimer() {
        stopTimer()
        seconds.value = initial
    }

    return {
        seconds,
        stopwatch,
        startTimer,
        stopTimer,
        resetTimer,
    }
}
