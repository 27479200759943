<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMember, ShiftMemberIssue } from '@app/types/shifts'
import { formatDistance } from 'date-fns'
import { computed } from 'vue'
import ShiftMemberIssueTemplate from './ShiftMemberIssueTemplate.vue'

const { shiftMember } = defineProps<{ issue: ShiftMemberIssue; shiftMember: ShiftMember }>()

const { translate, getLocale } = useLocale()

const timeEarly = computed(() =>
    formatDistance(shiftMember.shift.end, shiftMember.end, {
        locale: getLocale(),
    })
)
</script>

<template>
    <ShiftMemberIssueTemplate :issue> Finished {{ timeEarly }} early </ShiftMemberIssueTemplate>
</template>
