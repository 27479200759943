<script setup lang="ts">
import ContractForm from '@app/components/contracts/forms/ContractForm.vue'
import type { Contract, ContractModel, GeneratedFiles } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { addDays, addMonths, subDays } from 'date-fns'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])
const props = defineProps<{ model: Contract & GeneratedFiles }>()

const startDate = computed(() => addDays(new Date(props.model.end_date as string), 1))

const endDate = computed(() => {
    if (!props.model.max_end_date) {
        return null
    }

    const endDateWithSameDuration = subDays(addMonths(startDate.value, props.model.duration_in_months as number), 1)
    const maxEndDate = new Date(props.model.max_end_date)

    if (endDateWithSameDuration < maxEndDate) {
        return endDateWithSameDuration
    }

    return endDateWithSameDuration < maxEndDate ? endDateWithSameDuration : maxEndDate
})

const form = useForm<ContractModel>({
    phase: props.model.phase === 4 ? 4 : props.model.phase++,
    branch_id: props.model.branch_id,
    contract_hour_type_id: props.model.contract_hour_type_id,
    email: props.model.user?.email || props.model.email,
    phone_number: props.model.user?.phone_number || props.model.phone_number,
    job_title_id: props.model.job_title.id,
    contract_job_title: props.model.contract_job_title,
    gross_monthly_salary: props.model.gross_monthly_salary.amount,
    min_hours: props.model.min_hours,
    max_hours: props.model.max_hours,
    sign_by: props.model.sign_by,
    start_date: startDate.value,
    end_date: endDate.value,
    trial_period: props.model.trial_period,
    include_phone_allowance: !!props.model.phone_allowance_amount,
    phone_allowance_amount: props.model.phone_allowance_amount?.amount || null,
    retirement_provision: props.model.retirement_provision || 'appendix',
    involves_visiting_other_locations: props.model.involves_visiting_other_locations,
    business_entity: props.model.business_entity || '',
    send_code_of_conduct: false,
})

const readonly = computed(() => {
    let fields = ['start_date', 'email', 'phone_number']

    if (!props.model.max_end_date) {
        fields.push('end_date')
    }

    return fields
})
</script>

<template>
    <ContractForm
        :form
        :action="route('contract.renew', props.model.id)"
        method="post"
        @content:closed="$emit('content:closed')"
        :readonly="readonly"
        button-label="contract.renew"
    />
</template>
