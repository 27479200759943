<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import AvailabilityCard from '@app/components/shifts/availabilities/AvailabilityCard.vue'
import CreateAvailabilityForm from '@app/components/shifts/availabilities/forms/CreateAvailabilityForm.vue'
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import CreateIndividualShiftForm from '@app/components/shifts/shifts/forms/CreateIndividualShiftForm.vue'
import RefreshBriggsWalkerShiftsButton from '@app/components/shifts/shifts/RefreshBriggsWalkerShiftsButton.vue'
import ShiftCard from '@app/components/shifts/shifts/ShiftCard.vue'
import Button from '@app/components/ui/button/Button.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import Weekdays from '@app/components/ui/calendar/Weekdays.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import UserNameAndAvatar from '@app/components/users/UserNameAndAvatar.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useUserCan } from '@app/composables/useUserCan'
import type { Branch, ClientProject, LocationType, User, WorkType } from '@app/types/shared'
import type { Shift, ShiftAvailability, ShiftTemplate } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { eachDayOfInterval, endOfWeek, isPast, startOfWeek } from 'date-fns'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'

const { shiftAvailabilityTabs } = useNavigation()
const { can } = useUserCan()

const { branches, locationTypes, users, clientProjects, workTypes, selectedShift } = defineProps<{
    users: User[]
    branches: Branch[]
    locationTypes: LocationType[]
    clientProjects: ClientProject[]
    workTypes: WorkType[]
    userShiftTemplates: ShiftTemplate[]
    selectedShift: Shift | null
}>()

const emit = defineEmits(['create-shift:close', 'close'])

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['users'] })

const sortedDay = ref<Date | undefined>()
const selectedAvailability = ref<ShiftAvailability | null>(null)

const formShown = ref<'shift' | 'availability'>()

const selectedAvailabilities = ref<ShiftAvailability[]>([])

const weekdays = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

function closeForm() {
    selectedAvailabilities.value = []
    formShown.value = undefined
}

const sortUsers = (day: Date) => {
    sortedDay.value = day
    users.sort((a, b) => {
        const hasAvailabilityA = a.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())
        const hasAvailabilityB = b.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

        return hasAvailabilityA === hasAvailabilityB ? 0 : hasAvailabilityA ? -1 : 1
    })
}

function handleAvailabilityClick(availability: ShiftAvailability) {
    if (availability?.shift) {
        applyFilter({ ['selected_shift']: availability.shift.id }, { only: ['selectedShift'] })
        selectedAvailability.value = null
    } else {
        if (selectedAvailability.value?.id === availability.id) {
            selectedAvailability.value = null
        } else {
            applyFilter({ ['selected_shift']: null }, { only: ['selectedShift'] })
            selectedAvailability.value = availability
        }
    }
}

function handleAvailabilitySelect(availability: ShiftAvailability) {
    const index = selectedAvailabilities.value.findIndex((item) => item.id === availability.id)

    if (index !== -1) {
        selectedAvailabilities.value.splice(index, 1)
    } else {
        selectedAvailabilities.value.push(availability)
    }
}

const handleCloseShift = () => {
    applyFilter({ ['selected_shift']: null }, { only: ['selectedShift'] })
}

const selectedDate = ref(new Date())
const selectedUser = ref()

const handleClick = (user: User, day: Date) => {
    if (formShown.value === 'shift') {
        return
    }

    const hasNoAvailability = !user.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

    if (hasNoAvailability && !isPast(day.setHours(23, 59, 59))) {
        formShown.value = 'availability'
        selectedDate.value = day
        selectedUser.value = user
    }
}

function selectAllAvailability() {
    selectedAvailabilities.value = users.flatMap((user) => user.shift_availabilities.filter((availability) => !availability.shift))
}

function userAvailabilitiesForDay(user: User, day: Date) {
    return user.shift_availabilities.filter((item) => {
        return new Date(item.from).toDateString() === day.toDateString()
    })
}

watch(formShown, (newValue) => {
    if (newValue) {
        // Clear selected availability and shift when a form is shown
        selectedAvailability.value = null
        applyFilter({ ['selected_shift']: null }, { only: ['selectedShift'] })
    }
})
</script>

<template>
    <div>
        <ContentTabs bottomBorder :items="shiftAvailabilityTabs" />

        <div class="min-h-[24px]">
            <div class="flex items-start gap-4 scrollbar-hide">
                <div class="flex h-full w-full flex-col">
                    <Header :date="selectedDay">
                        <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" />
                        <Divider v-if="can('shifts.shift.store')" />
                        <Button v-if="can('shifts.shift.store')" class="relative" @click="formShown = 'shift'"> {{ $t('shifts.plan_shifts') }}</Button>
                        <Divider v-if="['sales-agent', 'promotions'].includes(route().params.workType)" />
                        <RefreshBriggsWalkerShiftsButton v-if="['sales-agent', 'promotions'].includes(route().params.workType)" :only="['users']" />
                    </Header>
                    <FilterSection :showPerPageOptions="false" class="-ml-4">
                        <DropdownMultiFilter border :items="clientProjects" :label="$t('attributes.client_project')" slug="client_project" />
                        <DropdownMultiFilter border :items="locationTypes" :label="$t('attributes.location_type')" slug="location_type" />
                        <DropdownMultiFilter border :items="branches" :label="$t('attributes.branch')" slug="branch" />
                        <ResetFilterButton
                            :href="route('shifts.planning.index', { workType: route().params.workType, week: route().params.week })"
                            :label="$t('buttons.clear')"
                        />
                    </FilterSection>
                    <div class="mt-2 flex flex-col-reverse items-start gap-2 md:flex-row">
                        <div
                            ref="container"
                            class="isolate flex flex-auto flex-col overflow-y-auto rounded-lg border-zinc-100 bg-white transition-width duration-100 ease-in-out scrollbar-hide sm:border"
                            :class="{ 'w-2/3': !!formShown || selectedAvailability, 'w-full': !(!!formShown || selectedAvailability) }"
                        >
                            <div class="flex w-[300%] flex-none flex-col md:w-[200%] lg:w-full">
                                <div class="sticky top-0 z-50 flex-none shadow-sm ring-1 ring-black ring-opacity-5">
                                    <div class="grid grid-cols-7 divide-x divide-zinc-100 border-b border-zinc-100 text-xs leading-4 text-zinc-500">
                                        <div class="sticky left-0 col-end-1 w-40 border-r border-zinc-100 bg-white md:w-52" />
                                        <Weekdays :days="weekdays" :sortedDay="sortedDay" shorthand sortable @sort="sortUsers" />
                                    </div>
                                </div>
                                <div class="flex">
                                    <table class="min-w-full table-fixed border-t-0">
                                        <tbody class="divide-y divide-zinc-100 bg-white">
                                            <tr
                                                v-for="user in users"
                                                class="row-span-4 grid min-h-16 w-full grid-cols-7 flex-col justify-between divide-x divide-zinc-100 text-sm"
                                            >
                                                <td
                                                    class="sticky left-0 z-40 col-span-1 col-end-1 flex w-40 items-center whitespace-normal border-r border-zinc-100 bg-white px-4 md:w-52"
                                                >
                                                    <UserNameAndAvatar :user="user" />
                                                </td>
                                                <td v-for="day in weekdays" class="flex max-h-full flex-col items-center overflow-hidden">
                                                    <div class="flex h-full w-full cursor-pointer flex-col gap-1" @click="handleClick(user, day)">
                                                        <ShiftAvailabilityEvent
                                                            v-for="availability in userAvailabilitiesForDay(user, day)"
                                                            :availability
                                                            :mode="formShown === 'shift' ? 'select' : 'normal'"
                                                            :selected="selectedAvailabilities.some((item) => item.id === availability.id)"
                                                            :disabled="!!availability.shift"
                                                            @select="handleAvailabilitySelect(availability)"
                                                            @event:clicked="handleAvailabilityClick(availability)"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Transition name="slide-fade" mode="out-in">
                            <AvailabilityCard v-if="selectedAvailability" @close="selectedAvailability = null" :availability="selectedAvailability" />
                        </Transition>

                        <Transition name="slide-fade" mode="out-in">
                            <ShiftCard v-if="selectedShift" @close="handleCloseShift" :shift="selectedShift" />
                        </Transition>

                        <Transition name="slide-fade" mode="out-in">
                            <CreateAvailabilityForm
                                v-if="formShown === 'availability'"
                                @close="closeForm"
                                :date="selectedDate"
                                :plannerShiftTemplates="userShiftTemplates"
                                :user="selectedUser"
                            />
                        </Transition>

                        <Transition name="slide-fade" mode="out-in">
                            <CreateIndividualShiftForm
                                v-if="formShown === 'shift'"
                                v-model="selectedAvailabilities"
                                @toggle="handleAvailabilitySelect"
                                @select:all="selectAllAvailability"
                                @close="closeForm"
                            />
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
