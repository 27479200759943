<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { computed } from 'vue'
import DismissButton from '../button/DismissButton.vue'

const {
    show,
    dismissable = true,
    size = 'lg',
    blurred = false,
} = defineProps<{
    show: boolean
    dismissable?: boolean
    size?: 'lg' | 'xl' | '2xl' | '4xl'
    blurred?: boolean
}>()

const modalSizes = {
    lg: 'sm:max-w-lg',
    xl: 'sm:max-w-xl',
    '2xl': 'sm:max-w-2xl',
    '4xl': 'sm:max-w-4xl',
}

const modalSize = computed(() => modalSizes[size])

defineEmits(['close'])
</script>

<template>
    <Teleport to="body">
        <TransitionRoot appear as="template" :show="show">
            <Dialog as="div" class="relative z-10" @close="$emit('close')">
                <!-- Overlay -->
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-zinc-700 bg-opacity-75 transition-opacity" :class="{ 'backdrop-blur-sm': blurred }" />
                </TransitionChild>

                <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 sm:p-0">
                        <TransitionChild
                            as="template"
                            enter="ease-out duration-300"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full" :class="modalSize">
                                <DismissButton v-if="dismissable" @click="$emit('close')" />
                                <slot />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </Teleport>
</template>
