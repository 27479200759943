<script setup lang="ts">
import { useTimer } from '@app/composables/useTimer'
import { useForm } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'
import Alert from '../ui/alert/Alert.vue'
import Button from '../ui/button/Button.vue'
import Form from '../ui/form/Form.vue'
import FormActions from '../ui/form/FormActions.vue'
import FormField from '../ui/form/FormField.vue'
import FormSection from '../ui/form/FormSection.vue'
import Input from '../ui/form/Input.vue'
import Radio from '../ui/form/Radio.vue'

type Scope = {
    id: number
    type: string
}

const { scope } = defineProps<{ scope: Scope; phone: string; email: string }>()

const emit = defineEmits(['login'])

const { seconds, stopwatch, startTimer, resetTimer } = useTimer({ mode: 'timer', initial: 60 })

const sendViaSms = ref(true)
const showCodeInput = ref(false)
const requested = ref(false)

const form = useForm({
    id: scope.id,
    type: scope.type,
    transport: 'sms',
    code: '',
})

watch(sendViaSms, () => {
    form.transport = sendViaSms.value ? 'sms' : 'email'
})

watch(requested, () => {
    if (requested.value) {
        startTimer()
    }
})

watch(seconds, () => {
    if (seconds.value === 0) {
        requested.value = false
        resetTimer()
    }
})

function requestCode() {
    form.post(route('otc.request'), {
        onSuccess() {
            showCodeInput.value = true
            requested.value = true
        },
    })
}

function confirm() {
    form.post(route('otc.authenticate'), {
        onSuccess() {
            emit('login')
        },
    })
}

function handleAlreadyHaveCode() {
    showCodeInput.value = true
    requested.value = false
}

const confirmButtonLabel = computed(() => (requested.value ? stopwatch : trans('auth.otc.request')))
</script>

<template>
    <Form :form @submit="confirm">
        <FormSection :title="$t('auth.otc.title')">
            <div v-if="$slots.description" class="col-span-12 text-sm text-gray-600">
                <slot name="description"></slot>
            </div>

            <Alert v-if="showCodeInput && requested" class="col-span-12" type="success">{{
                $t('auth.otc.code_sent', { recipient: sendViaSms ? phone : email })
            }}</Alert>

            <div v-if="!showCodeInput" class="col-span-12">
                <Radio v-model="sendViaSms" :value="true">{{ $t('auth.otc.sms', { phone }) }}</Radio>
                <Radio v-model="sendViaSms" :value="false">{{ $t('auth.otc.email', { email }) }}</Radio>
            </div>

            <FormField v-else prop="code" class="col-span-12">
                <Input v-model="form.code" :placeholder="$t('auth.otc.code_placeholder')" autofocus />
            </FormField>
        </FormSection>

        <FormActions>
            <template v-if="!showCodeInput">
                <Button type="button" color="ghost" @click="handleAlreadyHaveCode">{{ $t('auth.otc.already_have_code') }}</Button>

                <Button type="button" @click="requestCode" :disabled="requested">{{ confirmButtonLabel }}</Button>
            </template>

            <template v-else>
                <Button type="button" color="ghost" @click="showCodeInput = false">{{ $t('auth.otc.request') }}</Button>

                <Button v-if="showCodeInput" type="submit">{{ $t('buttons.confirm') }}</Button>
            </template>
        </FormActions>
    </Form>
</template>
