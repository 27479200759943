<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { Shift } from '@app/types/shifts'
import { getZonedDateTime } from '@app/utils/date'
import { type PropType } from 'vue'

defineProps({
    shift: {
        type: Object as PropType<Shift>,
        required: true,
    },
})

const { dbTranslate } = useLocale()
</script>

<template>
    <div class="mt-4 flex w-full justify-start gap-4 text-left">
        <div class="flex flex-col justify-start space-y-2 md:w-1/2">
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.work_type') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ dbTranslate(shift.clientProject.work_type.name) }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.client_project') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.clientProject.name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.location_type') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.locationType.name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.branch_id') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.branch.name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.application') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.application.name }}</span>
            </div>
        </div>
        <div class="flex flex-col justify-start space-y-2 md:w-1/2">
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.start') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getZonedDateTime(shift.start) }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.end') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getZonedDateTime(shift.end) }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.slots') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.slots }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.location') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.locations }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.external_id') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ shift.external_id }}</span>
            </div>
        </div>
    </div>
</template>
