<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import EditShiftForm from '../forms/EditShiftForm.vue'

const { shift } = defineProps<{ shift: Shift }>()
const { can } = useUserCan()
const show = computed(() => can('shifts.shift.update') && shift.editable)

const { toggle } = useActiveButton()
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.edit')" @click="toggle(EditShiftForm)">
        <PencilIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
