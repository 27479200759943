<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { useUserCan } from '@app/composables/useUserCan'
import Settings from '@app/layouts/Settings.vue'
import { Link, router, usePage } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{
    client_projects: ResourceCollection<ClientProject>
}>()

const user = usePage<PageProps>().props.user
const { can } = useUserCan()

function deleteClientProject(id) {
    router.delete(route('settings.client-project.delete', { id: id }))
}

function createClientProject() {
    router.get(route('settings.client-project.create'))
}

var columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        show: user.is_super_admin,
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name' },
    },
    {
        type: 'string',
        header: 'attributes.short_name',
        properties: { name: 'short_name' },
    },
    {
        type: 'string',
        header: 'settings.clients',
        properties: { name: 'name', relation: 'client' },
    },
    {
        name: 'actions',
        header: 'actions.header',
    },
]
</script>

<template>
    <Settings group="general">
        <div class="mb-6 md:flex md:items-center md:justify-between">
            <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                <div class="ml-2 mt-2 text-xl font-semibold leading-7 text-gray-900">
                    {{ $t('settings.client_projects') }}
                </div>
                <p class="ml-2 mt-1 truncate text-sm text-gray-500">
                    {{ $t('actions.view') + ' ' + $t('actions.update') + ' ' + $t('actions.delete') }}
                </p>
            </div>
            <div class="my-3 flex place-items-center">
                <Button v-if="can('client-project.store')" @click="createClientProject"> {{ $t('actions.create') }} </Button>
            </div>
        </div>
        <div class="mb-6 md:flex md:items-center md:justify-between">
            <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="client_projects" :columns="columns">
                <template v-slot:column.actions="{ item }">
                    <Link class="mr-2 text-primary-600" :href="route('settings.client-project.edit', { id: item.id })">
                        {{ $t('actions.update') }}
                    </Link>
                    <button type="button" class="text-primary-600" @click="deleteClientProject(item.id)">
                        {{ $t('actions.delete') }}
                    </button>
                </template>
            </DataTable>
        </div>
    </Settings>
</template>
