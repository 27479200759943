<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import type { PageProps } from '@app/types/inertia'
import { PlayCircleIcon } from '@heroicons/vue/20/solid'
import { router, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const shiftsToday = computed(() => usePage<PageProps>().props.shiftsToday)

// For the FCC shifts milestone, we will only support single shifts.
// Later on, we will have to adjust this button to support multiple shifts on one day.
// Something like https://tailwindui.com/components/application-ui/elements/dropdowns#component-f8a14da22f26a67757b19f2fe3ca00ed should suffice.
const showButton = computed(() => shiftsToday.value.length === 1 && ['planned', 'should-have-started'].includes(shiftsToday.value[0]?.shift_member_status))

function startShift() {
    router.post(
        route('shifts.member.start', shiftsToday.value[0]?.shift_member_id),
        {},
        {
            onSuccess() {
                handleSuccess()
            },
        }
    )
}

function handleSuccess() {
    setTimeout(function () {
        if (shiftsToday.value[0]?.shift_external_url) {
            window.open(shiftsToday.value[0].shift_external_url, '_blank')
        }

        if (shiftsToday.value[0].shift_project === 'fonky-recruitment-fcc') {
            router.get(
                route('recruitment.lead.index', {
                    funnelBlock: 'lead',
                    funnel: 'sales-agent',
                })
            )
        }
    }, 5000)
}
</script>

<template>
    <div v-if="showButton">
        <Button @click="startShift()" color="green">
            <PlayCircleIcon class="size-5 text-white" />
            <div>{{ $t('buttons.start_shift') }}</div>
        </Button>
    </div>
</template>
