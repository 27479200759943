<script setup lang="ts">
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import type { ShiftMember } from '@app/types/shifts'
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { ExclamationCircleIcon } from '@heroicons/vue/16/solid'
import ApproveShiftMember from '../members/actions/ApproveShiftMember.vue'
import ShiftMemberIssues from '../members/tabs/ShiftMemberIssues.vue'

const { shiftMember } = defineProps<{
    shiftMember: ShiftMember
}>()

const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="shiftMember" :dismissable="true" @close="emit('close')" class="max-w-[26rem]">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle>{{ shiftMember.shift_availability.userable.full_name }}</DetailCardTitle>
            <div class="flex flex-col items-center gap-4">
                <DetailCardLabel :label="$t('attributes.date')"> {{ getDateFromDateTime(shiftMember.shift.start) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.start')"> {{ getTimeFromDateTime(shiftMember.start) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.end')"> {{ getTimeFromDateTime(shiftMember.end) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.break_duration_minutes')"> {{ shiftMember.break_duration_minutes }} </DetailCardLabel>

                <div class="mt-4 grid grid-cols-2 gap-4">
                    <DetailItem :label="$t('attributes.branch_id')">
                        {{ shiftMember.shift.branch.name }}
                    </DetailItem>

                    <DetailItem :label="$t('attributes.client_project')">
                        {{ shiftMember.shift.client_project.name }}
                    </DetailItem>

                    <DetailItem :label="$t('attributes.location_type')">
                        {{ shiftMember.shift.location_type.name }}
                    </DetailItem>
                </div>
            </div>
        </div>
        <template #actions>
            <ApproveShiftMember :shift-member />
        </template>
        <template #tabs>
            <DetailCardTab v-if="shiftMember.issues" tab="issues" label="Issues">
                <template #icon>
                    <ExclamationCircleIcon class="size-5" />
                </template>
                <ShiftMemberIssues :shift-member />
            </DetailCardTab>
            <DetailCardTab tab="details" label="Details" />
        </template>
    </DetailCard>
</template>
