<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import MoneyInput from '@app/components/ui/form/MoneyInput.vue'
import { useLocale } from '@app/composables/useLocale'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { ContractHourType, ContractModel } from '@app/types/contracts'
import type { Branch, JobTitle, User } from '@app/types/shared'
import { durationFromStartAndEndDate } from '@app/utils/contracts/contract'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { EnvelopeIcon, EyeIcon } from '@heroicons/vue/20/solid'
import { usePage, type InertiaForm } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { formatISO } from 'date-fns'
import { computed, watch, watchEffect, type PropType } from 'vue'
import ContractTemplatePreview from '../templates/ContractTemplatePreview.vue'
import ContractEndDatePicker from './fields/ContractEndDatePicker.vue'

const emit = defineEmits(['content:closed', 'update:jobtitle'])

const props = defineProps({
    form: {
        type: Object as PropType<InertiaForm<ContractModel>>,
        required: true,
    },
    action: {
        type: String,
        required: true,
    },
    method: {
        type: String as PropType<'post' | 'put'>,
        default: 'post',
    },
    readonly: {
        type: Array as PropType<string[]>,
        default: () => [],
    },
    buttonLabel: {
        type: String,
        default: 'buttons.save',
    },
})

const { getLocale } = useLocale()

const dateFormat = 'dd-MM-yyyy'

const TYPE_ZERO_HOUR_ID = 1
const TYPE_MIN_MAX_HOUR_ID = 2
const TYPE_FIXED_HOUR_ID = 3

const retirementProvisionOptions = computed(() => [
    {
        name: 'contract.retirement_provisions.not_applicable',
        slug: null,
    },
    {
        name: 'contract.retirement_provisions.appendix',
        slug: 'appendix',
    },
    {
        name: 'contract.retirement_provisions.signed',
        slug: 'signed',
    },
    {
        name: 'contract.retirement_provisions.eligible',
        slug: 'eligible',
    },
])

const businessEntities = computed(() => [
    {
        name: 'Fonky B.V.',
        slug: 'Fonky B.V.',
    },
    {
        name: 'Fonky Group B.V.',
        slug: 'Fonky Group B.V.',
    },
])

const selectedRetirementProvision = useModelSelect(retirementProvisionOptions, props.form.retirement_provision, { by: 'slug' })
const selectedBusinessEntity = useModelSelect(businessEntities, props.form.business_entity, { by: 'slug' })

const jobTitles = computed(() => usePage().props.jobTitles as JobTitle[])
const branches = computed(() => usePage().props.branches as Branch[])
const contractHourTypes = usePage().props.contractHourTypes as ContractHourType[]
const signBy = usePage().props.signBy as User[]

const selectedJobTitle = useModelSelect<JobTitle>(jobTitles, props.form.job_title_id)
const selectedBranch = useModelSelect<Branch>(branches, props.form.branch_id)
const selectedHourType = useModelSelect<ContractHourType>(contractHourTypes, props.form.contract_hour_type_id)
const selectedSignBy = useModelSelect<User>(signBy, props.form.sign_by, { autoselectOnChange: true })

const formattedDuration = computed(() =>
    props.form.start_date && props.form.end_date ? durationFromStartAndEndDate(props.form.start_date, props.form.end_date, getLocale()) : null
)
const isMinMaxHoursContract = computed(() => selectedHourType.value?.id === TYPE_MIN_MAX_HOUR_ID)
const isZeroHoursContract = computed(() => selectedHourType.value?.id === TYPE_ZERO_HOUR_ID)
const minHoursLabel = computed(() => (selectedHourType.value?.id === TYPE_FIXED_HOUR_ID ? 'attributes.hours' : 'attributes.min_hours'))
const signByDisabled = computed(() => signBy.length === 0)
const phaseDisabled = computed(() => !props.form.start_date)
const phaseReadonly = computed(() => props.form.start_date && !props.form.end_date)

watch(
    () => props.form.start_date,
    (startDate) => {
        if (!startDate) {
            props.form.end_date = null
        }
    }
)

watch(selectedJobTitle, () => {
    emit('update:jobtitle', selectedJobTitle.value)

    if (selectedJobTitle.value) {
        props.form.contract_job_title = selectedJobTitle.value.name['nl']
    }
})

watchEffect(() => {
    props.form.contract_hour_type_id = selectedHourType.value?.id || null

    if (isZeroHoursContract.value) {
        props.form.min_hours = 0
    }

    if (!isMinMaxHoursContract.value) {
        props.form.max_hours = null
    }

    if (props.form.start_date && !props.form.end_date) {
        props.form.phase = 4
    }
})

function isReadonly(field: string) {
    return props.readonly.includes(field)
}

function submit() {
    props.form
        .transform((data) => ({
            ...data,
            start_date: data.start_date ? formatISO(data.start_date, { representation: 'date' }) : null,
            end_date: data.end_date ? formatISO(data.end_date, { representation: 'date' }) : null,
        }))
        [props.method](props.action, {
            preserveState: true,
            only: ['selectedContract', 'contracts', 'errors'],
            onSuccess() {
                emit('content:closed')
            },
        })
}
</script>
<template>
    <Form :form="form">
        <FormSection>
            <FormField prop="job_title_id" :label="$t('attributes.job_title_id')" class="col-span-12 sm:col-span-6">
                <Dropdown
                    id="job_title_id"
                    :label="translatedSelectPlaceholder('attributes.job_title_id')"
                    :items="jobTitles"
                    propertyName="name"
                    v-model="selectedJobTitle"
                    v-model:form="form.job_title_id"
                />
            </FormField>

            <FormField prop="contract_job_title" :label="$t('attributes.contract_job_title')" class="col-span-12 sm:col-span-6">
                <Input id="contract_job_title" v-model="form.contract_job_title" />
            </FormField>

            <FormField prop="branch_id" :label="$t('attributes.branch_id')" class="col-span-12 sm:col-span-6">
                <Dropdown
                    id="branch_id"
                    :label="translatedSelectPlaceholder('attributes.branch_id')"
                    :items="branches"
                    propertyName="name"
                    :translateDb="false"
                    v-model="selectedBranch"
                    v-model:form="form.branch_id"
                />
            </FormField>

            <FormField prop="involves_visiting_other_locations" class="col-span-12 sm:col-span-6 sm:mt-9">
                <Checkbox v-model="form.involves_visiting_other_locations">
                    {{ $t('attributes.involves_visiting_other_locations') }}
                </Checkbox>
            </FormField>

            <FormField prop="contract_hour_type_id" :label="$t('attributes.contract_hour_type_id')" class="col-span-12 lg:col-span-6">
                <Dropdown
                    id="contract_hour_type"
                    :label="translatedSelectPlaceholder('attributes.contract_hour_type_id')"
                    :items="contractHourTypes"
                    propertyName="name"
                    v-model="selectedHourType"
                    v-model:form="form.contract_hour_type_id"
                />
            </FormField>

            <FormField prop="min_hours" :label="$t(minHoursLabel)" class="col-span-6 lg:col-span-3">
                <Input id="min_hours" type="number" v-model="form.min_hours" :readonly="isZeroHoursContract" :disabled="!form.contract_hour_type_id" />
            </FormField>

            <FormField v-if="isMinMaxHoursContract" prop="max_hours" :label="$t('attributes.max_hours')" class="col-span-6 lg:col-span-3">
                <Input id="max_hours" type="number" v-model="form.max_hours" />
            </FormField>

            <FormField prop="start_date" :label="$t('attributes.start_date')" class="col-span-12 sm:col-span-6">
                <VueDatePicker
                    v-model="form.start_date"
                    :enable-time-picker="false"
                    :locale="'nl'"
                    text-input
                    auto-apply
                    :text-input-options="{ format: dateFormat }"
                    :format="dateFormat"
                    :disabled="isReadonly('start_date')"
                />
            </FormField>

            <FormField prop="end_date" :label="$t('attributes.end_date')" class="col-span-12 sm:col-span-6">
                <ContractEndDatePicker :start-date="form.start_date" v-model="form.end_date" :disabled="!form.start_date || isReadonly('end_date')" />

                <span class="mt-2 text-xs text-gray-500" v-if="formattedDuration">{{ $t(formattedDuration) }}</span>
            </FormField>

            <FormField prop="phase" :label="$t('attributes.phase')" class="col-span-12 sm:col-span-6">
                <Input id="phase" v-model="form.phase" autocomplete="off" :readonly="phaseReadonly" :disabled="phaseDisabled" />
            </FormField>

            <FormField
                prop="gross_monthly_salary"
                :label="$t('attributes.gross_monthly_salary')"
                class="col-span-12 sm:col-span-6"
                :hint="$t('contract.gross_monthly_salary_hint')"
            >
                <MoneyInput id="gross_monthly_salary" v-model="form.gross_monthly_salary" />
            </FormField>

            <FormField prop="email" :label="$t('attributes.email')" class="col-span-12 sm:col-span-6">
                <Input id="email" v-model="form.email" autocomplete="off" placeholder="user@example.com" :disabled="isReadonly('email')">
                    <template #icon>
                        <EnvelopeIcon />
                    </template>
                </Input>
            </FormField>

            <FormField prop="phone_number" :label="$t('attributes.phone_number')" class="col-span-12 sm:col-span-6">
                <Input id="phone_number" v-model="form.phone_number" autocomplete="off" :disabled="isReadonly('phone_number')" />
            </FormField>

            <FormField prop="trial_period" class="col-span-12">
                <Checkbox v-model="form.trial_period">
                    {{ $t('attributes.trial_period') }}
                </Checkbox>
            </FormField>

            <FormField prop="include_phone_allowance" class="col-span-12">
                <Checkbox v-model="form.include_phone_allowance">
                    {{ $t('attributes.include_phone_allowance') }}
                </Checkbox>
            </FormField>

            <FormField v-if="form.include_phone_allowance" prop="phone_allowance_amount" class="col-span-3" :label="$t('attributes.phone_allowance_amount')">
                <MoneyInput v-model="form.phone_allowance_amount" />
            </FormField>

            <FormField prop="business_entity" class="col-span-12" :label="$t('attributes.business_entity')">
                <Dropdown
                    :translateDb="false"
                    :items="businessEntities"
                    v-model="selectedBusinessEntity"
                    v-model:form="form.business_entity"
                    property-name="name"
                    by="slug"
                />
            </FormField>

            <FormField prop="retirement_provision" class="col-span-12" :label="$t('attributes.retirement_provision')">
                <Dropdown
                    :translateDb="false"
                    :items="retirementProvisionOptions"
                    v-model="selectedRetirementProvision"
                    v-model:form="form.retirement_provision"
                    property-name="name"
                    by="slug"
                />
            </FormField>

            <FormField prop="sign_by" :label="$t('attributes.sign_by')" class="col-span-12">
                <Dropdown
                    :hide-if-empty="false"
                    :items="signBy"
                    v-model="selectedSignBy"
                    v-model:form="form.sign_by"
                    propertyName="full_name"
                    :label="$t('form.select') + ' ' + $t('attributes.user').toLowerCase()"
                    :disabled="signByDisabled"
                />

                <p class="mt-2 text-xs text-gray-500">
                    {{ $t('contract.create.sign_by_hint', { role: 'contract-sign-as-employer' }) }}
                </p>
            </FormField>

            <FormField prop="send_code_of_conduct" class="col-span-12">
                <Checkbox v-model="form.send_code_of_conduct">
                    {{ $t('contract.send_code_of_conduct') }}
                </Checkbox>
            </FormField>

            <ContractTemplatePreview class="col-span-12" :model="form.data()" v-slot="{ showPreview }">
                <Button type="button" color="white" @click="showPreview">
                    <EyeIcon class="size-5" />
                    {{ $t('contract.create.preview') }}
                </Button>
            </ContractTemplatePreview>
        </FormSection>

        <FormActions>
            <CancelButton @click="emit('content:closed')" />
            <SaveButton type="button" @click="submit">{{ $t(buttonLabel) }}</SaveButton>
        </FormActions>
    </Form>
</template>
