<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Client, ClientProject } from '@app/types/shared'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { router, useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const { clients, client_project } = defineProps<{ client_project: ClientProject; clients: Client[] }>()

const selectedClient = useModelSelect(clients, client_project?.client_id)

const form = useForm({
    name: client_project?.name || null,
    short_name: client_project?.short_name || null,
    slug: client_project?.slug || null,
    client_id: client_project?.client_id || null,
    shift_external_url: client_project.shift_external_url || null,
    use_call: Boolean(client_project.use_call),
})

function submit() {
    if (client_project) {
        form.put(route('settings.client-project.update', client_project.id))
    } else {
        form.post(route('settings.client-project.store'))
    }
}
</script>

<template>
    <Form :form="form" @submit="submit" class="max-w-xl">
        <FormSection>
            <FormField prop="name" :label="$t('attributes.name')" class="col-span-12">
                <Input id="name" v-model="form.name" />
            </FormField>

            <FormField prop="short_name" :label="$t('attributes.short_name')" class="col-span-6">
                <Input id="short_name" v-model="form.short_name" />
            </FormField>

            <FormField prop="slug" label="Slug" class="col-span-6">
                <Input id="slug" v-model="form.slug" />
            </FormField>

            <FormField prop="client_id" :label="$t('settings.clients')" class="col-span-12">
                <Dropdown
                    :items="clients"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.client')"
                    :showLabel="false"
                    :translateDb="false"
                    v-model="selectedClient"
                    v-model:form="form.client_id"
                />
            </FormField>

            <FormField prop="shift_external_url" :label="$t('attributes.shift_external_url')" class="col-span-12">
                <Input id="shift_external_url" v-model="form.shift_external_url" />
            </FormField>
            <Checkbox id="use_call" v-model="form.use_call" class="col-span-12">
                {{ $t('attributes.use_call') }}
            </Checkbox>
        </FormSection>

        <FormActions>
            <Button color="black" type="button" @click="router.get(route('settings.client-project.index'))">{{ $t('buttons.back') }}</Button>
            <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
        </FormActions>
    </Form>
</template>
