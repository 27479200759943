<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import type { PageProps } from '@app/types/inertia'
import { StopCircleIcon } from '@heroicons/vue/20/solid'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

const shiftsToday = computed(() => usePage<PageProps>().props.shiftsToday)
const currentShift = computed(() => shiftsToday.value.filter((shift) => shift.shift_member_status === 'started'))

const emit = defineEmits<{
    clicked: [shiftMemberId: number]
}>()
</script>

<template>
    <div v-if="currentShift.length > 0">
        <Button @click="emit('clicked', currentShift[0].shift_member_id)" color="red">
            <StopCircleIcon class="size-5 text-white" />
            <div>{{ $t('buttons.end_shift') }}</div>
        </Button>
    </div>
</template>
