<script setup lang="ts" generic="T">
import type { ResourceCollection } from '@app/types/shared'
import axios from 'axios'
import { onMounted, ref, watch } from 'vue'
import { route } from 'ziggy-js'

const {
    initialData,
    routeName,
    routeParams = {},
} = defineProps<{
    initialData: ResourceCollection<T>
    routeName: string
    routeParams?: Record<string, string | number>
}>()

const rootElement = ref(null)
const targetElement = ref(null)

const items = ref(initialData.data)
const nextPage = ref(getNextPageFromPaginatedData(initialData))
const isLoading = ref(false)

// reset pagination on reload
watch(
    () => initialData,
    () => {
        items.value = initialData.data
        nextPage.value = getNextPageFromPaginatedData(initialData)
    }
)

function getNextPageFromPaginatedData(data: typeof initialData) {
    if (data.current_page < data.last_page) {
        return data.current_page + 1
    }

    return null
}

async function fetchNextPageData() {
    isLoading.value = true

    const { data } = await axios.get(
        route(routeName, {
            ...route().params,
            ...routeParams,
            page: nextPage.value,
        })
    )

    nextPage.value = getNextPageFromPaginatedData(data)

    items.value = [...items.value, ...data.data]

    isLoading.value = false
}

function observeElementScrolling(entries) {
    entries.forEach(async (entry) => {
        if (entry.isIntersecting && nextPage.value && !isLoading.value) {
            fetchNextPageData()
        }
    })
}

onMounted(() => {
    const observer = new IntersectionObserver(observeElementScrolling, {
        root: rootElement.value,
        rootMargin: '0px 0px 30% 0px',
    })

    observer.observe(targetElement.value)

    return () => {
        observer.disconnect()
    }
})
</script>

<template>
    <ul role="list" ref="rootElement">
        <slot :items="items"></slot>
        <div ref="targetElement"></div>
    </ul>
</template>
