<script setup lang="ts">
import type { Branch, ClientProject, User } from '@app/types/shared'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { useForm, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import CancelButton from '../ui/button/CancelButton.vue'
import SaveButton from '../ui/button/SaveButton.vue'
import Form from '../ui/form/Form.vue'
import FormActions from '../ui/form/FormActions.vue'
import FormField from '../ui/form/FormField.vue'
import FormSection from '../ui/form/FormSection.vue'
import Select from '../ui/form/select/Select.vue'
import SelectOption from '../ui/form/select/SelectOption.vue'

const { model } = defineProps<{ model: User }>()

const emit = defineEmits(['content:closed'])

const form = useForm({
    branch_id: model.branch_id,
    client_project_id: model.client_project_id,
    branches: model.branches?.map((branch) => branch.id) ?? [],
    client_projects: model.client_projects?.map((project) => project.id) ?? [],
})

const branches = computed(() => usePage().props.branches as Branch[])
const selectableMainBranches = computed(() => branches.value.filter((item) => form.branches.includes(item.id)))

const clientProjects = computed(() => usePage().props.clientProjects as ClientProject[])
const selectableClientProjects = computed(() => clientProjects.value.filter((item) => form.client_projects.includes(item.id)))

function submit() {
    form.post(route('user.working-range.store', model), {
        only: ['selectedUser'],
        onSuccess() {
            emit('content:closed')
        },
    })
}
</script>

<template>
    <Form :form="form" @submit="submit">
        <FormSection>
            <FormField prop="branches" :label="$t('user.attributes.branches')" class="col-span-6">
                <Select multiple v-model="form.branches" :placeholder="translatedSelectPlaceholder('user.attributes.branches')">
                    <SelectOption v-for="branch in branches" :value="branch.id" :label="branch.name" :key="branch.id" />
                </Select>
            </FormField>

            <FormField prop="branch_id" :label="$t('user.attributes.branch_id')" class="col-span-6">
                <Select
                    v-model="form.branch_id"
                    :placeholder="translatedSelectPlaceholder('user.attributes.branch_id')"
                    :disabled="selectableMainBranches.length === 0"
                >
                    <SelectOption v-for="branch in selectableMainBranches" :value="branch.id" :label="branch.name" :key="branch.id" />
                </Select>
            </FormField>

            <FormField prop="client_projects" :label="$t('user.attributes.client_projects')" class="col-span-6">
                <Select multiple v-model="form.client_projects" :placeholder="translatedSelectPlaceholder('user.attributes.client_projects')">
                    <SelectOption v-for="clientProject in clientProjects" :value="clientProject.id" :label="clientProject.name" :key="clientProject.id" />
                </Select>
            </FormField>

            <FormField v-if="selectableClientProjects" prop="client_project_id" :label="$t('user.attributes.client_project_id')" class="col-span-6">
                <Select
                    v-model="form.client_project_id"
                    :placeholder="translatedSelectPlaceholder('user.attributes.client_project_id')"
                    :disabled="selectableClientProjects.length === 0"
                >
                    <SelectOption
                        v-for="clientProject in selectableClientProjects"
                        :value="clientProject.id"
                        :label="clientProject.name"
                        :key="clientProject.id"
                    />
                </Select>
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('content:closed')" />
            <SaveButton />
        </FormActions>
    </Form>
</template>
