<script setup lang="ts">
import Card from '@app/components/ui/card/Card.vue'
import type { ContractModel } from '@app/types/contracts'
import type { JobTitle } from '@app/types/shared'
import { useForm } from '@inertiajs/vue3'
import { addMonths, subDays } from 'date-fns'
import { ref, watchEffect } from 'vue'
import { route } from 'ziggy-js'
import ContractForm from './ContractForm.vue'

defineEmits(['close'])

const form = useForm<ContractModel>({
    phase: 1,
    branch_id: null,
    job_title_id: null,
    contract_job_title: '',
    contract_hour_type_id: null,
    gross_monthly_salary: null,
    min_hours: null,
    max_hours: null,
    start_date: null,
    end_date: null,
    sign_by: null,
    email: '',
    phone_number: '',
    trial_period: true,
    involves_visiting_other_locations: false,
    include_phone_allowance: false,
    phone_allowance_amount: null,
    retirement_provision: null,
    business_entity: 'Fonky B.V.',
    send_code_of_conduct: true,
})

const DEFAULT_CONTRACT_DURATION = 12

const selectedJobTitle = ref<JobTitle | null>()

watchEffect(() => {
    if (form.start_date) {
        form.end_date = subDays(addMonths(form.start_date, selectedJobTitle.value?.contract_duration_months ?? DEFAULT_CONTRACT_DURATION), 1)
    }
})
</script>

<template>
    <Card :dismissable="true" @close="$emit('close')">
        <div class="text-left text-xl font-semibold leading-7 text-zinc-900">
            {{ $t('contract.create.title') }}
        </div>
        <ContractForm
            :form
            :title="$t('contract.create')"
            :action="route('contract.store')"
            @content:closed="$emit('close')"
            @update:jobtitle="(jobTitle) => (selectedJobTitle = jobTitle)"
        />
    </Card>
</template>
