<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Input from '@app/components/ui/form/Input.vue'
import Label from '@app/components/ui/form/Label.vue'
import type { ShiftMember } from '@app/types/shifts'
import { getTimeFromDateTime } from '@app/utils/date'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])

const { model } = defineProps<{ model: ShiftMember }>()

const form = useForm({
    actual_start_time: getTimeFromDateTime(model.start),
    actual_end_time: getTimeFromDateTime(model.end),
    note: '',
})

function submit() {
    form.post(route('shifts.member.approve', model.id), {
        onSuccess() {
            emit('content:closed')
        },
    })
}
</script>

<template>
    <Form :form @submit="submit">
        <FormSection>
            <div class="col-span-12">
                <Label>{{ $t('shifts.quality_control.adjust_time') }}</Label>

                <div class="mt-1 flex gap-4">
                    <FormField prop="actual_start_time" class="col-span-6">
                        <Input v-model="form.actual_start_time" placeholder="09:00" />
                    </FormField>

                    <FormField prop="actual_end_time" class="col-span-6">
                        <Input v-model="form.actual_end_time" placeholder="15:00" />
                    </FormField>
                </div>
            </div>

            <FormField prop="note" class="col-span-12">
                <FormTextArea v-model="form.note" :placeholder="$t('note.placeholder_optional')" />
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('content:closed')"></CancelButton>
            <SaveButton>{{ $t('buttons.approve') }}</SaveButton>
        </FormActions>
    </Form>
</template>
