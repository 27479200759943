<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Card from '@app/components/ui/card/Card.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import { useLocale } from '@app/composables/useLocale'
import { type User } from '@app/types/shared'
import type { ShiftHour, ShiftTemplate } from '@app/types/shifts'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { getUniqueModelsFromArray } from '@app/utils/object'
import { ArrowsPointingInIcon, CalendarIcon, ClockIcon, MapIcon, PaperClipIcon } from '@heroicons/vue/20/solid'
import { useForm } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { uniqBy } from 'lodash'
import { computed, type ComputedRef, ref, watchEffect } from 'vue'
import { route } from 'ziggy-js'

const { date, plannerShiftTemplates, user } = defineProps<{
    date: Date
    plannerShiftTemplates: ShiftTemplate[]
    user: User
}>()

const emit = defineEmits(['close'])
const { getLocale } = useLocale()
const selectedDate = ref(date)

type FormModel = {
    user: User | null
    client_project_id: number | null
    location_type_id: number | null
    branch_id: number | null
    shift_availabilities: Record<string, { date: string; shift_hour_id: number | null }>
}

const form = useForm<FormModel>({
    user: user,
    client_project_id: user.client_project_id,
    location_type_id: null,
    branch_id: user.branch_id,
    shift_availabilities: {},
})

const clientProjects = computed(() => getUniqueModelsFromArray(plannerShiftTemplates, 'client_project'))

const selectableLocationTypes = computed(() => {
    let templates = plannerShiftTemplates.filter((shiftTemplate) => shiftTemplate.client_project_id === form.client_project_id)

    return getUniqueModelsFromArray(templates, 'location_type')
})

const selectableBranches = computed(() => {
    let templates = plannerShiftTemplates.filter(
        (shiftTemplate) => shiftTemplate.client_project_id === form.client_project_id && shiftTemplate.location_type_id === form.location_type_id
    )

    return getUniqueModelsFromArray(templates, 'branch')
})

const selectableShiftHours = computed(() => {
    let shiftHours = plannerShiftTemplates
        .filter(
            (shiftTemplate) =>
                shiftTemplate.client_project_id === form.client_project_id &&
                shiftTemplate.location_type_id === form.location_type_id &&
                shiftTemplate.branch_id === form.branch_id
        )
        .reduce((acc, shiftTemplate) => {
            return [...acc, ...shiftTemplate.shift_hours]
        }, [] as ShiftHour[])

    return uniqBy(shiftHours, 'id')
}) as ComputedRef<ShiftHour[]>

const key = computed(() => selectedDate.value.toDateString())

watchEffect(() => {
    const defaultShiftHours = selectableShiftHours.value.find((item) => item.is_default)

    form.shift_availabilities[key.value] = {
        date: key.value,
        shift_hour_id: defaultShiftHours?.id ?? null,
    }
})

const submit = () => {
    form.post(route('shifts.availability.store'), {
        preserveScroll: true,
        onSuccess: () => emit('close'),
        onError: () => {
            let keys = Object.entries(form.errors)

            keys.forEach(([key, message]) => {
                if (key.startsWith('shift_availabilities.')) {
                    const [_, index] = key.split('.')

                    if (!form.errors.shift_availabilities) {
                        form.errors.shift_availabilities = {}
                    }

                    form.errors.shift_availabilities[index] = message
                }
            })
        },
    })
}
</script>

<template>
    <Card dismissable @close="emit('close')" id="detail-card" class="w-full md:w-1/3">
        <div class="flex w-full flex-col items-start gap-4">
            <div class="flex gap-2">
                <div class="w-full text-center text-lg font-medium leading-6 text-gray-900">{{ $t('shifts.create_availability.title') }}</div>
            </div>
            <div class="flex w-full flex-col gap-4">
                <div class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <PaperClipIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <Select
                            class="w-2/3"
                            :border="false"
                            v-model="form.client_project_id"
                            :placeholder="translatedSelectPlaceholder('attributes.client_project')"
                            :disabled="clientProjects.length === 0"
                            autoselect
                        >
                            <SelectOption
                                v-for="clientProject in clientProjects"
                                :value="clientProject.id"
                                :label="clientProject.name"
                                :key="clientProject.id"
                            />
                        </Select>
                    </div>
                </div>
                <div v-if="selectableLocationTypes.length > 0" class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <ArrowsPointingInIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />

                        <Select
                            class="w-2/3"
                            :border="false"
                            v-model="form.location_type_id"
                            :placeholder="translatedSelectPlaceholder('attributes.location_type')"
                            autoselect
                        >
                            <SelectOption
                                v-for="locationType in selectableLocationTypes"
                                :value="locationType.id"
                                :label="locationType.name"
                                :key="locationType.id"
                            />
                        </Select>
                    </div>
                </div>
                <FieldError :message="form.errors.location_type_id" />
                <div v-if="selectableBranches.length > 0" class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <MapIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <Select
                            class="w-2/3"
                            :border="false"
                            v-model="form.branch_id"
                            :placeholder="translatedSelectPlaceholder('attributes.branch')"
                            autoselect
                        >
                            <SelectOption v-for="branch in selectableBranches" :value="branch.id" :label="branch.name" :key="branch.id" />
                        </Select>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <CalendarIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <VueDatePicker
                            class="w-2/3"
                            id="availability_date"
                            v-model="selectedDate"
                            :locale="getLocale().code"
                            :auto-position="false"
                            auto-apply
                            :enable-time-picker="false"
                            text-input
                            format="dd-MM-yyyy"
                            placeholder="Select a date"
                        />
                    </div>
                    <FieldError
                        v-if="form.errors.shift_availabilities && key in form.errors.shift_availabilities"
                        :message="form.errors.shift_availabilities[key]"
                        class="mt-2"
                    />
                </div>
                <div class="flex gap-2">
                    <div v-if="selectableShiftHours.length > 0" class="flex flex-col gap-4">
                        <div class="flex items-center space-x-4">
                            <ClockIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select class="w-full" v-model="form.shift_availabilities[key].shift_hour_id" placeholder="select">
                                <SelectOption
                                    v-for="shiftHours in selectableShiftHours"
                                    :value="shiftHours.id"
                                    :label="`${shiftHours.start_time} - ${shiftHours.end_time}`"
                                    :key="shiftHours.id"
                                />
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-row-reverse justify-between">
            <SaveButton @click="submit">Create</SaveButton>
        </div>
    </Card>
</template>
