<script setup lang="ts">
import PersonalAvailabilityForm from '@app/components/shifts/availabilities/forms/PersonalAvailabilityForm.vue'
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import Button from '@app/components/ui/button/Button.vue'
import Body from '@app/components/ui/calendar/body/Body.vue'
import TimeGrid from '@app/components/ui/calendar/body/TimeGrid.vue'
import TimeSlot from '@app/components/ui/calendar/body/TimeSlot.vue'
import Calendar from '@app/components/ui/calendar/Calendar.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import type { ShiftAvailability, ShiftTemplate } from '@app/types/shifts'
import { eachDayOfInterval, endOfWeek, isPast, startOfWeek } from 'date-fns'
import { computed, ref } from 'vue'

defineProps<{
    availabilities: ShiftAvailability[]
    userShiftTemplates: ShiftTemplate[]
}>()

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['availabilities'] })

const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

const isPastWeek = computed(() => isPast(endOfWeek(selectedDay.value, { weekStartsOn: 1 })))
const openAvailabilityForm = ref(false)
</script>

<template>
    <ModalLayout :show="openAvailabilityForm" @close="openAvailabilityForm = false">
        <PersonalAvailabilityForm :date="selectedDay" :user-shift-templates="userShiftTemplates" @close="openAvailabilityForm = false" />
    </ModalLayout>
    <Calendar :days>
        <Header :date="selectedDay">
            <Button :disabled="isPastWeek" @click="openAvailabilityForm = true">
                {{ $t('calendar.availability.add') }}
            </Button>
            <Divider />
            <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" />
        </Header>

        <Body>
            <TimeGrid :start="9" :end="22">
                <TimeSlot
                    v-for="availability in availabilities"
                    :from="availability.shift?.start ?? availability.from"
                    :to="availability.shift?.end ?? availability.to"
                >
                    <ShiftAvailabilityEvent :availability />
                </TimeSlot>
            </TimeGrid>
        </Body>
    </Calendar>
</template>
