<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import type { Branch, ClientProject, LocationType } from '@app/types/shared'
import type { Shift } from '@app/types/shifts'
import { DATE_FORMAT, getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { useForm, usePage } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { formatISO } from 'date-fns'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])

const { model } = defineProps<{ model: Shift }>()

const branches = computed(() => usePage().props.branches as Branch[])
const clientProjects = computed(() => usePage().props.clientProjects as ClientProject[])
const locationTypes = computed(() => usePage().props.locationTypes as LocationType[])

const workTypeSlug = computed(() => route().params.workType)

const form = useForm({
    client_project_id: model.clientProject.id,
    branch_id: model.branch.id,
    location_type_id: model.locationType.id,
    date: getDateFromDateTime(model.start),
    start_time: getTimeFromDateTime(model.start),
    end_time: getTimeFromDateTime(model.end),
})

function updateShift() {
    form.transform((data) => ({
        ...data,
        date: data.date ? formatISO(data.date, { representation: 'date' }) : null,
    })).put(route('shifts.shift.update', model.id), {
        onSuccess() {
            emit('content:closed')
        },
    })
}
</script>

<template>
    <Form :form @submit="updateShift">
        <FormSection>
            <FormField prop="branch_id" class="col-span-12" :label="$t('attributes.branch_id')">
                <Select v-model="form.branch_id" :placeholder="translatedSelectPlaceholder('user.attributes.branch_id')">
                    <SelectOption v-for="branch in branches" :label="branch.name" :value="branch.id" :key="branch.id" />
                </Select>
            </FormField>

            <FormField prop="client_project_id" class="col-span-12" :label="$t('attributes.client_project')">
                <Select v-model="form.client_project_id" :placeholder="translatedSelectPlaceholder('attributes.client_project')">
                    <SelectOption v-for="clientProject in clientProjects" :label="clientProject.name" :value="clientProject.id" :key="clientProject.id" />
                </Select>
            </FormField>

            <FormField v-if="workTypeSlug !== 'contact-center'" prop="location_type_id" class="col-span-12" :label="$t('attributes.location_type')">
                <Select v-model="form.location_type_id" :placeholder="translatedSelectPlaceholder('attributes.location_type')">
                    <SelectOption v-for="locationType in locationTypes" :label="locationType.name" :value="locationType.id" :key="locationType.id" />
                </Select>
            </FormField>

            <FormField prop="date" :label="$t('attributes.date')" class="col-span-12">
                <VueDatePicker
                    v-model="form.date"
                    :enable-time-picker="false"
                    :locale="'nl'"
                    text-input
                    auto-apply
                    :text-input-options="{ format: DATE_FORMAT }"
                    :format="DATE_FORMAT"
                    :min-date="new Date()"
                />
            </FormField>

            <FormField prop="start_time" :label="$t('attributes.start')" class="col-span-6">
                <Input v-model="form.start_time" />
            </FormField>

            <FormField prop="end_time" :label="$t('attributes.end')" class="col-span-6">
                <Input v-model="form.end_time" />
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('content:closed')" />
            <SaveButton />
        </FormActions>
    </Form>
</template>
