<script setup lang="ts">
import FormStepItem from '@app/components/ui/multiStepForm/FormStepItem.vue'
import FormSteps from '@app/components/ui/multiStepForm/FormSteps.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { computed } from 'vue'

const { steps } = defineProps<{
    steps: FormStep[]
}>()

const visibleSteps = computed(() => steps.filter((step) => !step.isHidden))
</script>

<template>
    <h1 v-if="$slots.title" class="text-xl font-semibold leading-7 text-gray-900">
        <slot name="title"></slot>
    </h1>
    <p v-if="$slots.description" class="mt-4 text-sm font-normal leading-6 text-gray-600">
        <slot name="description"></slot>
    </p>

    <nav class="my-4">
        <FormSteps :steps="visibleSteps">
            <FormStepItem v-for="(step, index) in visibleSteps" :step-number="index + 1" :step="step" />
        </FormSteps>
    </nav>

    <section class="w-full max-w-4xl rounded-md border-gray-100 sm:border sm:p-6">
        <slot></slot>
    </section>
</template>
