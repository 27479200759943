<script setup lang="ts">
import type { GraphSummary } from '@app/types/metrics'
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'

const { summary, type } = defineProps<{ summary: GraphSummary; type: string }>()

const delta = computed(() => summary.current - summary.previous)
const isPositiveTrend = computed(() => (summary.trendType === 'positive' ? delta.value >= 0 : delta.value <= 0))
const arrow = computed(() => (delta.value > 0 ? ArrowTrendingUpIcon : ArrowTrendingDownIcon))
</script>

<template>
    <div class="flex items-center gap-4">
        <p class="text-2xl font-semibold leading-8 text-gray-900">{{ summary.current }}</p>
        <div
            v-if="delta !== 0"
            :class="[isPositiveTrend ? 'text-green-500' : 'text-red-500', 'flex items-center gap-1']"
            v-tooltip="$t(`dashboard.summary.${type}`)"
        >
            <div :class="[isPositiveTrend ? 'bg-green-100' : 'bg-red-100', 'flex h-6 w-6 items-center justify-around rounded-full']">
                <component :is="arrow" class="size-5" :class="[isPositiveTrend ? 'text-green-500' : 'text-red-500']" />
            </div>
            <span>{{ delta }}</span>
        </div>
    </div>
</template>
