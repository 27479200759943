<script setup>
import IncomingCall from '@app/components/calls/overlay/IncomingCall.vue'
import ModalLayout from '@app/components/calls/overlay/ModalLayout.vue'
import OngoingCall from '@app/components/calls/overlay/OngoingCall.vue'
import Content from '@app/components/layout/Content.vue'
import SideBar from '@app/components/layout/sidebar/SideBar.vue'
import TopBar from '@app/components/layout/topbar/TopBar.vue'
import Toast from '@app/components/ui/toast/Toast.vue'
import { useTwilioCalls } from '@app/composables/calls/useTwilioCalls'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useInertiaRequestProgress } from '@app/composables/useInertiaRequestProgress'
import { useLocale } from '@app/composables/useLocale'
import { useLogout } from '@app/composables/useLogout'
import { Head } from '@inertiajs/vue3'
import * as Sentry from '@sentry/vue'
import { computed, onMounted, provide, ref } from 'vue'

const { activeNavItem } = useNavigation()

const props = defineProps({
    branches: Array,
    funnels: Array,
    funnelBlocks: Array,
    statuses: Array,
    user: Object,
})

Sentry.getCurrentScope().setUser({
    id: props.user.id,
    username: props.user.email,
    email: props.user.email,
})

provide('branches', props.branches)
provide('funnels', props.funnels)
provide('funnelBlocks', props.funnelBlocks)
provide('statuses', props.statuses)

// Layout
const mobileMenuOpen = ref(false)
const sideBarOpen = ref(true)

provide('mobileMenuOpen', mobileMenuOpen)
provide('sideBarOpen', sideBarOpen)

const { onLogout } = useLogout(props.user)
const { translate } = useLocale()

onLogout(() => {
    const delayLogoutInMs = 1000
    setTimeout(() => window.location.reload(), delayLogoutInMs)
})

const { captureProgress } = useInertiaRequestProgress()

onMounted(() => captureProgress())

// Twilio
const {
    connectTwilioDevice,
    disconnectTwilioDevice,
    startOutgoingCall,
    endCall,
    acceptIncomingCall,
    rejectIncomingCall,
    callStatus,
    recipient,
    showCallOverlay,
    muted,
    callDirection,
    loadLead,
    callInProgress,
} = useTwilioCalls(props.user)

provide('twilio', {
    connectTwilioDevice,
    disconnectTwilioDevice,
    startOutgoingCall,
    callStatus,
    callInProgress,
})

const isIncoming = computed(() => callDirection.value === 'incoming' && ['ringing', 'rejected'].includes(callStatus.value))
</script>

<template>
    <Toast />

    <Head v-if="activeNavItem" :title="translate(activeNavItem.name)"></Head>
    <div class="flex h-dvh">
        <SideBar :user="user" />
        <div class="flex h-full flex-1 flex-col overflow-hidden">
            <TopBar :user="user" />
            <div class="relative flex h-full flex-1 flex-row overflow-hidden">
                <Transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <ModalLayout v-if="showCallOverlay" v-slot="{ collapsed }" :collapsible="!isIncoming" class="transition-all duration-1000 ease-in-out">
                        <IncomingCall v-if="isIncoming" :recipient="recipient" @accept="acceptIncomingCall" @reject="rejectIncomingCall" />

                        <OngoingCall
                            v-else
                            :collapsed="collapsed"
                            :status="callStatus"
                            :recipient="recipient"
                            v-model:muted="muted"
                            :direction="isIncoming ? 'incoming' : 'outgoing'"
                            @dismiss="showCallOverlay = false"
                            @disconnect="endCall"
                            @create-lead="loadLead"
                        />
                    </ModalLayout>
                </Transition>
                <Content>
                    <slot />
                </Content>
            </div>
        </div>
    </div>
</template>
